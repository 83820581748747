export const Skills = [
    "Java",
    "Springboot",
    "Maven",
    "Kafka",
    "GraphQL",
    "Javascript",
    "React",
    "Vue",
    "NodeJs",
    "Express",
    "SQL",
    "Angular",
    "NoSQL",
    "CSS/SCSS",
    "Framer Motion",
    "REST",
    "Git",
    "Python",
    "C#",
    "C"
]